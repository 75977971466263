import React from 'react'
import PropTypes from 'prop-types'
import Profile from './Profile'
import { Image } from '@chakra-ui/react'
import { User } from '@auth0/auth0-react'

const Header = ({navigation}) => {
  return (
    <header className="bg-gray-100">
      <nav className="mx-auto w-screen px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-indigo-500 py-6 lg:border-none">
          <div className="flex items-center">
            <a href="#">
              <span className="sr-only">BrainMaps</span>
              <Image src={"logowithoutwords.svg"} boxSize="75px" alt="" />
            </a>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <a key={link.name} href={link.href} className="text-base font-medium text-indigo-500 hover:text-gray-700 duration-300 ease-in-out">
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="ml-10 space-x-4">
            <Profile/>
          </div>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {

}

export default Header
