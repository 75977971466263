import React from 'react'
import PropTypes from 'prop-types'
import {
    AcademicCapIcon,
    BanknotesIcon,
    ArrowSmallRightIcon,
    ArrowUpOnSquareStackIcon,
    CheckBadgeIcon,
    ClockIcon,
    GlobeEuropeAfricaIcon,
    ReceiptRefundIcon,
    UsersIcon,
  } from '@heroicons/react/24/outline'
  
  const actions = [
    {
      title: 'Register a brain',
      href: '/register',
      icon: ArrowSmallRightIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
      content: 'Register your brain data with BrainMaps'
    },
    {
      title: 'Browse open data',
      href: '/browse',
      icon: GlobeEuropeAfricaIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      content: 'Explore our growing collection of high quality brain data'
    },
    {
      title: 'View our documentation',
      href: 'https://developers.brainmaps.io',
      icon: UsersIcon,
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
      content: 'Learn how to interface with the BrainMaps API to interrogate data and build your own extensions'
    },
    {
      title: 'Host a BrainMaps instance',
      href: '/create',
      icon: ArrowUpOnSquareStackIcon,
      iconForeground: 'text-yellow-700',
      iconBackground: 'bg-yellow-50',
      content: 'Become part of the BrainMaps community by hosting your own data and joining our network'
    },
    // {
    //   title: 'Submit an expense',
    //   href: '#',
    //   icon: ReceiptRefundIcon,
    //   iconForeground: 'text-rose-700',
    //   iconBackground: 'bg-rose-50',
    // },
    // {
    //   title: 'Training',
    //   href: '#',
    //   icon: AcademicCapIcon,
    //   iconForeground: 'text-indigo-700',
    //   iconBackground: 'bg-indigo-50',
    // },
  ]

const CardActionGrid = () => {
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg width-4xl bg-gray-200 shadow max-w-7xl mx-auto my-16 sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
    {actions.map((action, actionIdx) => (
        <div
        key={action.title}
        className={classNames(
            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
        )}
        >
        <div>
            <span
            className={classNames(
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white'
            )}
            >
            <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
        </div>
        <div className="mt-8">
            <h3 className="text-lg font-medium">
            <a href={action.href} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {action.title}
            </a>
            </h3>
            <p className="mt-2 text-sm text-gray-500" data-tooltip-content='Select an item'>
            {action.content}
            </p>
        </div>
        <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
        >
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
        </span>
        </div>
    ))}
    </div>
    )
}

CardActionGrid.propTypes = {}

export default CardActionGrid