//create a react stateless component that creates a grid of Brain Atlases (cuttlefish, octopus, squid, lizard, mouse, rat)

import React from 'react';
import { Box, CardHeader, Grid, Image, Link } from '@chakra-ui/react';
import Atlas from '../components/Atlas';

const Atlases = ( {atlases} ) => {
    return (
        <>
        <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={2} p={8} maxW={"5xl"} margin="auto">
            {atlases.map(atlas => (
                <Link key={atlas.id} href={`/atlases/${atlas.name.toLowerCase()}`}>
                    <Box key={atlas.id}>
                        <Atlas atlas={atlas} />
                    </Box>
                </Link>
            ))}
        </Grid>
        </>
    )
}

export default Atlases;
