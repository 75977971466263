import {React, useState} from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import  About from './pages/About'
import Users from './pages/Users'
import Atlases from './pages/Atlases'
import Atlas from './components/Atlas'
import CreatePage from './pages/CreatePage'
import NotFound from './pages/NotFound'
import Home from './pages/Home'
import users from './users'
import RenderResults from './components/RenderResults';
import { Tooltip } from 'react-tooltip'
import "react-cmdk/dist/cmdk.css";
import atlasList from './atlasList';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client';


const client = new ApolloClient({
  uri: 'http://api.brainmaps.io/graphql',
  cache: new InMemoryCache(),
});

export default function Main() {

  const [open, setOpen] = useState(true);
  const [search, setSearch] = useState("");


return (
    <Router>
      <ApolloProvider client={client}>
        <Auth0Provider
        domain="dev-bzj4usag.us.auth0.com"
        clientId="ouyh4Ms232Jz1TyY68lnkswKMzoHM82X"
        redirectUri={"http://localhost:5173"}>
          <ChakraProvider>
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="*" element={<NotFound/>}/>
              <Route exact path="/atlases" element={<Atlases atlases={atlasList}/>}>
                <Route path=":atlasName" element={<Atlas/>}/>
              </Route>
              <Route exact path="/about" element={<About/>}/>
              <Route exact path="/users" element={<Users/>}/>
              <Route exact path="/create" element={<CreatePage/>}/>
            </Routes>
          </ChakraProvider>
        </Auth0Provider>
      </ApolloProvider>
    </Router>
)
}