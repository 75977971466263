import React from 'react'
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const NotFound = props => {
  return (
    <Box textAlign="center" py={10} px={6} className="mx-auto">
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text">
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={'gray.500'} mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Link to="/">
        <Button
          colorScheme="teal"
          bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
          color="white"
          variant="solid">
          Return to homepage
        </Button>
      </Link>
    </Box>
  )
}

NotFound.propTypes = {

}

export default NotFound
