import React from "react";
import { Box, Heading, Text, Flex, Image } from "@chakra-ui/react";

const Atlas = ( {atlas} ) => {
  return (
    <Box key={atlas.ID} width="100%" p="1rem" mb="1rem" border={"1px"} color="gray.100" rounded={"lg"}>
        <Flex>
        <Heading as="h4" size="lg" color={"#7a7a7a"} mr={4}>
            {atlas.name}
        </Heading>
        <Image
            src={atlas.thumbnail}
            alt={atlas.name}
            height="50px"
            mb="1rem"
            rounded={"full"}
        />
        </Flex>
        <Text color="gray.700" fontSize="md">Uploader: {atlas.uploader}</Text>
        <Flex>
            <Text color="gray.700" fontSize="xs" mr={2}>Segments: {atlas.segments}</Text>
            <Text color="gray.700" fontSize="xs" mr={2}>Slices: {atlas.slices}</Text>
        </Flex>
    </Box>
    )
}

export default Atlas;
