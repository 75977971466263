import React from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { Avatar, AvatarBadge, AvatarGroup, Text } from '@chakra-ui/react'

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
      return <div className="text-gray-600">Loading ...</div>;
  }

  if (isAuthenticated) {
    return (

      <div className="flex flex-row items-center mx-2">
        <Text className="text-gray-500" fontSize="lg" mr={2}>{user.name}</Text>
        <Avatar name={user.name} src={user.picture} className="mx-4"/>
        <LogoutButton/>
      </div>
    );
  }
  else {
    return (
      <LoginButton/>
    )
  }
}

Profile.propTypes = {

}

export default Profile
