import {
  Button,
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Code,
  StackDivider,
  SimpleGrid,
  Stack,
  Select,
  Text,
  useBreakpointValue,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useClipboard,
  VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';


const CreatePage = () => {
    const [awsId, setAwsId] = useState('');
    const [awsSecretKey, setAwsSecretKey] = useState('');
    const [awsRegion, setAwsRegion] = useState('');
    const [terraformScript, setTerraformScript] = useState('');
    const {onCopy, value, setValue, hasCopied} = useClipboard("");

    const handleSubmit = e => {
      e.preventDefault();
  
      const script = `
        provider "aws" {
          access_key = "${awsId}"
          secret_key = "${awsSecretKey}"
          region     = "${awsRegion}"
        }
        
        # Creating key_pair for SSH in AWS instance
        
        resource "tls_private_key" "createkey" {
          algorithm = "RSA"
          rsa_bits  = 4096
        }
        
        resource "aws_key_pair" "generated_key" {
          key_name   = "terraform-key"
          public_key = tls_private_key.createkey.public_key_openssh
        }
        
        resource "null_resource" "savekey" {
          depends_on = [
            tls_private_key.createkey,
          ]
          provisioner "local-exec" {
            command = "echo  '\${tls_private_key.createkey.private_key_pem}' > wordpress_key.pem"
          }
        }
        
        
        resource "aws_instance" "nginx" {
          ami             = "ami-0ecc74eca1d66d8a6"
          instance_type   = "t2.micro"
          key_name        = aws_key_pair.generated_key.key_name
          # security_groups = ["open"] # your security group name
        
          connection {
            type        = "ssh"
            user        = "ec2-user"
            private_key = tls_private_key.createkey.private_key_pem
            host        = aws_instance.nginx.public_ip
          }
        
          # provisioner "file" {
          #   source      = "../brainmaps-api"
          #   destination = "/"
          # }
        
          # provisioner "file" {
          #   source      = "../brainmaps.io/build"
          #   destination = "/"
          # }
        
          tags = {
            Name = "BrainMapsAPIInstance"
          }
        }
        
        # Storing IP address in file
        resource "null_resource" "getIp" {
          provisioner "local-exec" {
            command = "echo  \${aws_instance.nginx.public_ip} > publicip.txt"
          }
        }
        
        output "public_ip" {
          value = aws_instance.nginx.public_ip
        }

      `;
      // Generate Terraform script with the provided AWS credentials
  
      // Return the generated Terraform script to the user
      setTerraformScript(script);
      setValue(terraformScript);
      return <code>{terraformScript}</code>;
    };
  
    return (
      <Box w="3xl" p={8} m={"auto"}>
      <form onSubmit={handleSubmit}>
      <VStack
        spacing={8}
        align={'center'}
      >
        <label htmlFor="aws-id" id="IDLabel" data-tooltip-content="You will find your AWS ID when you create an AWS account">AWS ID:</label>
        <Input
          type="text"
          id="aws-id"
          border="1px solid #E2E8F0"
          borderColor="gray.300"
          value={awsId}
          onChange={e => setAwsId(e.target.value)}
        />
  
        <label htmlFor="aws-secret-key">AWS Secret Key:</label>
        <Input
          type="text"
          id="aws-secret-key"
          value={awsSecretKey}
          onChange={e => setAwsSecretKey(e.target.value)}
        />
  
        <label htmlFor="aws-region">AWS Region:</label>

        <Select 
          placeholder='Select AWS region'
          onChange={e => setAwsRegion(e.target.value)}
        
        >
          <option value='us-east-1'>us-east-1</option>
          <option value='us-east-2'>us-east-2</option>
          <option value='us-west-1'>us-west-1</option>
          <option value='ap-east-1'>ap-east-1</option>
          <option value='ap-south-1'>ap-south-1</option>
          <option value='eu-central-1'>eu-central-1</option>
          <option value='eu-west-1'>eu-west-1</option>
          <option value='eu-west-2'>eu-west-2</option>
          <option value='eu-west-3'>eu-west-3</option>
          <option value='eu-north-1'>eu-north-1</option>
          <option value='me-south-1'>me-south-1</option>
          <option value='sa-east-1'>sa-east-1</option>

        </Select>
  
        <Button
          type="submit"
          size="lg"
          margin="auto"
          bgColor="blue.500"
          color="white"
          mb={8}
        >
        Generate Terraform Script
        </Button>
      </VStack>
      </form>
      <Flex mb={2}>

      <Code
        display="block"
        textAlign={"left"}
        whiteSpace="pre"
        
        rounded={"4px"}
        padding={"xl"}
        value={value}
        >
        {terraformScript}
      </Code>
      <Button 
        onClick={onCopy}
        ml={2}
        bgColor="blue.500"
        color="white"
      >
      {hasCopied ? "Copied!" : "Copy"}
      </Button>
      </Flex>
      <Tooltip anchorId="IDLabel" />
      </Box>
    );
  };
  
  export default CreatePage;
