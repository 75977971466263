import React from 'react'
import Header from '../components/Header'
import CardActionGrid from '../components/CardActionGrid'
import { Text } from '@chakra-ui/react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Atlases from './Atlases'
import Atlas from '../components/Atlas'
import atlasList from '../atlasList'




const Home = () => {

  const navigation = [
    { name: 'Atlases', href: '/atlases' },
    { name: 'Register a Brain', href: 'register' },
    { name: 'Browse Data', href: '/browse' },
    { name: 'Create Instance', href: '/create' },
    { name: 'Users', href: '/users' },
    { name: 'Documentation', href: 'https://developers.brainmaps.io' },
  ]
  return (
    <>
    <Header navigation={navigation} id="header"/>
    <Text fontSize="2xl" color="blue.400" pt="16" pl="16">Welcome to Brainmaps</Text>
    <Text fontSize="lg" id="subheader" color="blue.700" px="16" py="1"  data-tooltip-content="something goes here">BrainMaps is a novel brain atlas 
    platform for exploration of neural anatomy data, automatic brain registration 
    and data-driven segmentation, for <i>any species</i>.</Text>
    <CardActionGrid/>
    <Tooltip anchorId="subheader" place='top'/>
    <Text fontSize="lg" color="blue.700" px="16" py="1">Explore Commonly Used Atlases</Text>
    <Atlases atlases={atlasList}/>
    </>
  )
}

export default Home