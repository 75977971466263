import React from 'react'
import { Box, Center, Stack, Button, useColorModeValue, SimpleGrid} from '@chakra-ui/react'
import CardWithAvatar from '../components/CardWithAvatar'
import UserInfo from '../components/UserInfo'
import { LoremIpsum, Avatar } from 'react-lorem-ipsum';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery } from '@apollo/client';

const GET_USERS = gql`
  query getUsers {
    allUsers {
      id
      username
      email
      bio
      location
      group {
        id
        name
      }
    }
  }
`;


const Users = () => {

  const { loading, error, data } = useQuery(GET_USERS);

  if (loading) return 'Loading...';
  if (error) return `Error : ${error.message}`;

  return (
    <Box px={{ base: '6', md: '8' }} py="12">
    <Box as="section" maxW={{ base: 'xs', md: '3xl' }} mx="auto">
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6">
        {data.allUsers.map(user => {
          return (
            <CardWithAvatar key={user.id}>
              <UserInfo mt="3" name={user.username}/>
              <UserInfo mt="3" name={user.email}/>
              <UserInfo mt="3" fontSize="xs" name={user.bio}/>
              <Button variant="outline" colorScheme="teal" rounded="full" size="sm" width="full">
                View Profile
              </Button>
            </CardWithAvatar>
          )
        })}
      </SimpleGrid>
    </Box>
  </Box>
  );
}

export default Users