import React from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonGroup } from '@chakra-ui/react'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
    return <Button onClick={() => loginWithRedirect()} color={"white"} backgroundColor={"blue.400"}>Log In</Button>;}

LoginButton.propTypes = {}

export default LoginButton