import cuttlefish from './assets/cuttlefish.svg'
import octopus from './assets/octopus.svg'
import squid from './assets/squid.svg'
import lizard from './assets/iguana.svg'
import mouse from './assets/mouse.svg'
import rat from './assets/rat.svg'

const atlasList = [
    { 
      id: 1,
      name: "Cuttlefish",
      uploader: "John Doe",
      segments: 100,
      slices: 100,
      thumbnail: cuttlefish,
      href: "",
    },
    {
      id: 2,
      name: "Octopus",
      uploader: "Jane Doe",
      segments: 100,
      slices: 100,
      thumbnail: octopus,
      href: "",
    },
    {
      id: 3,
      name: "Squid",
      uploader: "Jane Doe",
      segments: 100,
      slices: 100,
      thumbnail: squid,
      href: "",
    },
    {
      id: 4,
      name: "Lizard",
      uploader: "Jane Doe",
      segments: 100,
      slices: 100,
      thumbnail: lizard,
      href: "",
    },
    {
      id: 5,
      name: "Mouse",
      uploader: "Jane Doe",
      segments: 100,
      slices: 100,
      thumbnail: mouse,
      href: "",
    },
    {
      id: 6,
      name: "Rat",
      uploader: "Jane Doe",
      segments: 100,
      slices: 100,
      thumbnail: rat,
      href: "",
    },
]

export default atlasList;